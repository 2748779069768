<template>
  <div class="statement-page">
    <Head title="资金流水" />
    <Warpper top="40">
      <div class="page-loadmore-wrapper" style="overflow: scroll;height: 100%;">
        <mt-loadmore
          :bottom-method="loadBottom"
          @bottom-status-change="handleBottomChange"
          :bottom-all-loaded="allLoaded"
          ref="loadmore"
          :auto-fill="false"
        >
          <ul class="page-loadmore-list statement-list">
            <li v-for="(item,index) in list" :key="index">
              <h5>
                <p>
                  <span>[{{['收入','支出'][item.types]}}]</span>
                  {{item.title}}
                </p>
                <span>{{['','-'][item.types]}}{{item.money}}</span>
              </h5>
              <p>{{item.times}}</p>
              <p>{{item.mark}}</p>
            </li>
          </ul>

          <div slot="bottom" class="mint-loadmore-bottom">
            <span
              v-show="bottomStatus !== 'loading'"
              :class="{ 'is-rotate': bottomStatus === 'drop' }"
            >↑</span>
            <span v-show="bottomStatus === 'loading'">
              <mt-spinner type="snake"></mt-spinner>
            </span>
          </div>

          <None class="y-none" value="没有更多数据啦" v-if="allLoaded&&list.length==0" />

        </mt-loadmore>
      </div>
    </Warpper>
  </div>
</template>

<script>
import None from "components/None";
import { reqGetOrderLog } from "@/network/api";
export default {
  name: "statement",
  components: { None },
  created() {},
  data() {
    return {
      allLoaded: false,
      bottomStatus: "",
      wrapperHeight: 0,
      list: [],
      page: 0,
      total: 1,
      first:true
    };
  },
  mounted() {
    this.loadBottom();
  },
  methods: {
    handleBottomChange(status) {
      this.bottomStatus = status;
    },
    loadBottom() {
      if (this.page >= this.total) return;
      reqGetOrderLog({
        page: this.page + 1
      }).then(res => {
        const { code, msg, backdata } = res;
        if (code != 0) return this.$toast({ message: msg });
        this.page = backdata.now;
        this.total = backdata.count;
        this.list = [...this.list, ...backdata.list];
        if (this.page >= this.total) {
          this.allLoaded = true;
        }
        if(this.first) this.$refs.loadmore.onBottomLoaded();
        this.first = false;
      });
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.statement-list > li {
  box-sizing: border-box;
  padding: 0.44rem 0.29rem;
  background-color: #fff;
  position: relative;
  &:not(:last-of-type)::after {
    content: "";
    display: block;
    bottom: 0;
    width: 6.9rem;
    height: 1px;
    background: #e1e1e1;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(0.5);
  }
  h5 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      span {
        color: #ff1010;
      }
    }
    span {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #007867;
    }
  }
  &>p {
    font-size: 0.24rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    margin-top: 0.24rem;
  }
}
.mint-loadmore-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>